class ProductGalleryItem {
    constructor(divId) {
        if (divId) {
            this.DivContainer = divId;
        } else {
            this.DivContainer = document;
        }
    }

    /**
    * This method finds all 'product-gallery-item' and assigns a click event listener
    */

    activateLinks() {
        const gallery = $('.product-gallery-item-container').find('.product-gallery-item');
        gallery.each(function (i, e) {
            this.targetURL = e.getAttribute('data-target-url');
            $(e).on('click', function () {              
                window.location.href = this.targetURL;                             
            });            
        });
        
    }

    /**
    * This method handles on clicks product-gallery-item elements. If the click target exists inside the 'description' area
    * and is a link, the user will be navigated to the target of that link.  A click anywhere else will navigate them to the block's URL property from EpiServer
    */

    Init() {            
        this.activateLinks();
    }
}