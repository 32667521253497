function ElevatorButton() {
    const elevatorBtn = document.getElementById('scrollToTopBtn');

    window.onscroll = function () {
        document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ?
            elevatorBtn.classList.remove('hidden') :
            elevatorBtn.classList.add('hidden');
    };

    elevatorBtn && elevatorBtn.addEventListener('click', function () {
        scrollToTop(0);
    });
}

ElevatorButton();