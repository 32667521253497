class MobileNavigation {
    constructor() {
        this.init();
    }
    init() {
        const mainMobileNav = document.querySelector('.js-mobile-nav');
        const mainNavContainer = document.querySelector('.js-mobile-nav .mobile-nav');
        const mainNav = document.querySelector('.js-mobile-nav .mobile-nav__wrapper');
        const mainNavItems = document.querySelectorAll('.js-mobile-nav .nav-link-submenu');
        const toggle = document.querySelector('.navbar-toggler');
        const body = document.querySelector('body');

        const hideMenu = (event) => {
            if (!mainNav.contains(event.target) && event.target !== mainNav) {                
                body.classList.remove('mobile-menu-opened');
                mainMobileNav.classList.remove('show');
                toggle.classList.add('collapsed');                
            }
        };

        const toggleMenu = (event) => {
            body.classList.toggle('mobile-menu-opened');              
        };

        if (toggle) {
            toggle.addEventListener('click', toggleMenu);
        }

        if (mainNavContainer) {
            mainNavContainer.addEventListener('click', hideMenu);
        }

        if (mainNavItems.length) {
            mainNavItems.forEach((element) => {
                const target = element.dataset.target;
                const submenu = document.getElementById(element.dataset.target);

                if (target !== undefined && submenu !== null) {
                    const btnBack = submenu.querySelector('.nav-back-link');

                    btnBack.addEventListener('click', () => {
                        submenu.classList.remove('active');
                    });

                    element.addEventListener('click', (event) => {
                        event.preventDefault();
                        submenu.classList.add('active');
                    });
                }
            });
        }
    }
}