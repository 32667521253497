class FoundationCms {
    init() {
        axios.defaults.headers.common['Accept'] = '*/*';
        window.notification = new NotifyHelper();
        feather.replace();

        var header = new Header();
        header.init();

        let accordionObject;
        document.querySelectorAll('.accordion').forEach(accordion => {
            accordionObject = new Accordion(accordion.id);
            accordionObject.Init();
        })
      
        var productGalleryItem = new ProductGalleryItem();
        productGalleryItem.Init();

        var registrationPage = new Registration();

        const forgotPassword = new ForgotPassword();

        //Mobile Navigation
   /*      var params = { searchBoxId: "#mobile-searchbox", openSearchBoxId: "#open-searh-box", closeSearchBoxId: "#close-search-box", sideBarId: "#offside-menu-mobile", openSideBarId: "#open-offside-menu" }
        var mobileNav = new MobileNavigation(params);
        mobileNav.Init(); */

        var mobileNav = new MobileNavigation();


        // Selection CM
        var selection = new Selection();
        selection.Init();
        // ---End

        // Dropdown
        var dropdowns = new Dropdown();
        dropdowns.Init();
        // --- End

        //Search Box
        var searchBox = new SearchBox();
        searchBox.Init();
        // --- End

        //Content Search
        var contentSearch = new ContentSearch();
        contentSearch.Init();
        // --- End

        // Pdf preview
        var pdfPreview = new PdfPreview();
        pdfPreview.Render();
        // --- End

        // Password Field Show/Hide Feature
        const revealPassword = new RevealPassword();
        revealPassword.Init();
        // --- End


        // Account Actiovation Pages
        const accountActivation = new AccountActivate();        
    }
}