class Checkout {
    constructor() {
        this.loadingBox = $('.loading-box');
        this.maxNoteLength = 255;
        this.checkoutForm = $('.jsCheckoutForm');
        this.addressSubForm = $('#shipping-address-form');
        this.formNewAddressErrors = $(".jsFormNewAddressErrors");
        this.verificationToken = this.checkoutForm.find('input[name="__RequestVerificationToken"]').val();

        this.addAddressFormValues = {
            'ShippingAddress_AddressId': "",
            'ShippingAddress_FullName': "",
            'ShippingAddress_DaytimePhoneNumber': "",
            'ShippingAddress_Organization': "",
            'ShippingAddress_CountryCode': "",
            'ShippingAddress_Line1': "",
            'ShippingAddress_Line2': "",
            'ShippingAddress_City': "",
            'ShippingAddress_CountryRegion_Region': "",
            'ShippingAddress_PostalCode': "",
            'ShippingAddress_AddToAddressBook': ""
        };

        this.editAddressFormValues = this.getAddressFormData();

        this.initHandlers();
        this.initDatePicker();
        this.initErrors();
    }

    initHandlers() {
        this.checkoutForm
            .on("click", ".jsChangeAddress", (e) => {
                e.preventDefault();
                this.changeAddress();
            })
            .on("click", ".jsSaveAddress", (e) => {
                e.preventDefault();
                this.addressForm();
            })
            .on("click", ".jsEditAddressBtn", (e) => {
                e.preventDefault();
                this.editAddressHandler();
            })
            .on("click", ".jsAddAddressBtn", (e) => {
                e.preventDefault();
                $(".jsTextRegion").val("");
                this.addAddressHandler();
            })
            .on("click", ".jsCancelAddress", (e) => {
                e.preventDefault();
                this.cancelAddressHandler();
            })
            .on("click", ".jsCheckoutSubmit", (e) => {
                e.preventDefault();
                this.submitCheckout();
            })
            .on("click", ".jsPaymentOption", (e) => {
                this.toggleOption($(e.target));
            })
            .on("click", ".jsOpenCalendar", (e) => {
                this.toggleCalendar($(e.target));
            })
            .on("click", ".jsSelectDate", (e) => {
                e.preventDefault();
                this.selectDate($(e.target));
            })
            .on("keyup", ".jsNote", (e) => {
                e.preventDefault();
                this.limitCharters($(e.target));
            })
            .on("change", '#SaveNewCardInWallet', (e) => {
                this.toggleSaveNewCard($(e.target));
            })
            .on("change", '.jsRegion', (e) => {
                this.updateRegionInput(e.target);
            })
            .on("change", ".jsSelectCountry", (e) => {
                setTimeout(this.updateRegionInput, 1000);
            });

        if ($(".jsNote").length !== 0) {
            this.limitCharters($(".jsNote"));
        }
    }

    updateRegionInput(target) {
        let actualRegionInput = $('#ShippingAddress_CountryRegion_Region');
        let selectedRegion = $('.jsRegion:not(.d-none)').val();
        actualRegionInput.val(selectedRegion);
    }

    selectDate(target) {
        let wrapper = target.closest(".jsCalendarWrapper");
        let date = wrapper.find(".jsShipmentDatePicker").datepicker('getFormattedDate');

        wrapper.find("input").val(date);
        this.toggleCalendar(target);
    }

    toggleSaveNewCard(target) {
        let checkbox = target.closest("#SaveNewCardInWallet");
        let value = checkbox && checkbox.val() === 'true' ? 'false' : 'true';
        checkbox && checkbox.val(value);
    }

    toggleCalendar(target) {
        let calendar = target.closest(".jsCalendarWrapper").find(".jsCalendar");

        if (calendar.hasClass("request-dates__calendar--open")) {
            calendar.removeClass("request-dates__calendar--open");
        } else {
            $(".request-dates__calendar--open").removeClass("request-dates__calendar--open");
            calendar.addClass("request-dates__calendar--open");
        }
    }

    toggleOption(target) {
        $(".jsPaymentOption").removeClass("payment-option--selected");
        target.closest(".jsPaymentOption").addClass("payment-option--selected");

        let radioBtn = $('.payment-option--selected').find("input[type='radio']");
        radioBtn.prop("checked", true);

        let submitButton = $('.jsCheckoutSubmit');
        if (radioBtn.val() !== 'NewCard') {
            let newText = submitButton.data('order-text');
            submitButton.text(newText);
            $('.payment-option__m-l input').attr('checked', false);
            $('.jsCheckoutPaymentText').addClass('d-none');
        } else {
            let newText = submitButton.data('payment-text');
            submitButton.text(newText);
            $('.payment-option__m-l input').attr('checked', true);
            $('.jsCheckoutPaymentText').removeClass('d-none');
        }
    }

    validateAddressForm() {
        for (let prop in this.addAddressFormValues) {
            $("#" + prop).valid();
        }
    }

    hasErrorsInAddressForm() {
        scrollToElem('.input-validation-error');
        return $("[aria-invalid='true']", this.addressSubForm).length > 0;
    }

    submitCheckout() {
        const inst = this;
        // check validation
        if (!this.checkoutForm.valid()) {
            this.validateAddressForm();

            //open address form if it  has errors
            if (this.hasErrorsInAddressForm()) {
                this.addressSubForm.addClass("show");
            }

            return;
        }
                
        const formObj = this.checkoutForm[0];       
        const radioBtn = $("input[name='PaymentMethod'][type='radio']:checked");
        if (radioBtn.val() === 'NewCard') {
            $('.loading-box').show();
            $.ajax({
                url: '/Elavon/GetOrderToken',
                dataType: "json",
                type: "POST",
                contentType: 'application/json',
                data: {},
                success: function (data) {
                    document.getElementById('elavontoken').value = data;
                    openLightbox(() => { formObj.submit(); });
                    inst.sendGA4ShippingAndBillingInfo();
                },
                error: function () {
                    alert('error');
                },
                finally: function() {
                    $('.loading-box').hide(); 
                }
            });
        }
        else {
            formObj.submit();
            inst.sendGA4ShippingAndBillingInfo();
        }
    }
    
    editAddressHandler() {
        let isEditFormOpen = this.addressSubForm.hasClass("show") && (this.addressSubForm.attr('data-type') === 'edit');

        if (isEditFormOpen) {
            this.editAddressFormValues = this.getAddressFormData();
            this.addressSubForm.removeClass("show");
        } else {
            this.fillAddressForm(this.editAddressFormValues);
            this.addressSubForm.attr('data-type', 'edit');
            this.addressSubForm.addClass("show");

            if ($('.jsSelectRegion option').length <= 1) {
                $('.jsSelectRegion').addClass('d-none');
                $('.jsTextRegion').removeClass('d-none');
            }

            this.validateAddressForm();
        }
    }

    addAddressHandler() {
        let isAddFormOpen = this.addressSubForm.hasClass("show") && (this.addressSubForm.attr('data-type') === 'add');

        if (isAddFormOpen) {
            this.addAddressFormValues = this.getAddressFormData();
            this.addressSubForm.removeClass("show");
        } else {
            this.fillAddressForm(this.addAddressFormValues);
            this.addressSubForm.attr('data-type', 'add');
            this.addressSubForm.addClass("show");
            $('.jsSelectRegion').addClass('d-none');
            $('.jsTextRegion').removeClass('d-none');            
        }
    }

    cancelAddressHandler() {
        this.addressSubForm.removeClass("show");
    }

    limitCharters(target) {
        let textLength = this.maxNoteLength - target.val().length;
        $('.jsNoteLength').text(textLength);
    }

    changeAddress() {
        this.showLoading();

        const modal = $('#addressBook');
        const selectedAddress = $(modal).find('input[type=radio]:checked').first();

        const data = new FormData();
        data.append("addressId", $(selectedAddress).val());
        data.append("__RequestVerificationToken", this.verificationToken);

        const url = $(modal).data('url');

        axios.post(url, data)
            .then((response) => {
                if (response.status === 200) {
                    this.hideLoading();
                    this.updateAddressSection(response.data);
                } else {
                    notification.Error(response.statusText);
                }
            })
            .catch((error) => {
                notification.Error(error);
            })
            .finally(() => {
                this.hideLoading();
            });
    }

    updateAddressSection(data) {
        if (data) {
            $('.jsShippingAddress').replaceWith(data);
            $('#addressBook').modal('hide');

            this.addressSubForm = $('#shipping-address-form');
            this.editAddressFormValues = this.getAddressFormData();
        }
    }

    addressForm() {
        let form = $('.jsFormNewAddress')[0];

        let bodyFormData = this.setAddressFormData(form, "ShippingAddress");
        let url = $(form).data('url');

        this.validateAddressForm();

        if (this.hasErrorsInAddressForm())
            return;

        this.hideAddressFormeErrors();
        this.showLoading();

        axios({
            method: 'post',
            url: url,
            data: bodyFormData
        })
            .then((response) => {
                if (response.status === 200) {
                    this.updateAddressSection(response.data);
                    this.editAddressFormValues = this.getAddressFormData();
                }
            })
            .catch((error) => {
                notification.Error(error);
                this.showAddressFormErrors(error.response.statusText);
            })
            .finally(() => {
                this.hideLoading();
            });
    }

    showAddressFormErrors(error) {
        this.formNewAddressErrors.html(error);
        this.formNewAddressErrors.addClass("checkout__error");
    }

    hideAddressFormeErrors() {
        this.formNewAddressErrors.html("");
        this.formNewAddressErrors.removeClass("checkout__error");
    }

    setAddressFormData(form, formName) {
        let bodyFormData = new FormData();
        bodyFormData.set('AddressId', $(`#${formName}_AddressId`, form).val() || '');
        bodyFormData.set('FullName', $(`#${formName}_FullName`, form).val());
        bodyFormData.set('Organization', $(`#${formName}_Organization`, form).val());
        bodyFormData.set('DaytimePhoneNumber', $(`#${formName}_DaytimePhoneNumber`, form).val());
        bodyFormData.set('CountryCode', $(`#${formName}_CountryCode`, form).val());
        bodyFormData.set('Line1', $(`#${formName}_Line1`, form).val());
        bodyFormData.set('Line2', $(`#${formName}_Line2`, form).val());
        bodyFormData.set('City', $(`#${formName}_City`, form).val());
        bodyFormData.set('CountryRegion.Region', $("#ShippingAddress_CountryRegion_Region", form).val());
        bodyFormData.set('PostalCode', $(`#${formName}_PostalCode`, form).val());
        bodyFormData.set('AddToAddressBook', $(`#${formName}_AddToAddressBook`, form)[0].checked);
        bodyFormData.set('__RequestVerificationToken', this.verificationToken);

        return bodyFormData;
    }

    getAddressFormData() {
        return {
            'ShippingAddress_AddressId': $(`#ShippingAddress_AddressId`).val(),
            'ShippingAddress_FullName': $(`#ShippingAddress_FullName`).val(),
            'ShippingAddress_DaytimePhoneNumber': $(`#ShippingAddress_DaytimePhoneNumber`).val(),
            'ShippingAddress_Organization': $(`#ShippingAddress_Organization`).val(),
            'ShippingAddress_CountryCode': $(`#ShippingAddress_CountryCode`).val(),
            'ShippingAddress_Line1': $(`#ShippingAddress_Line1`).val(),
            'ShippingAddress_Line2': $(`#ShippingAddress_Line2`).val(),
            'ShippingAddress_City': $(`#ShippingAddress_City`).val(),
            'ShippingAddress_CountryRegion_Region': $(`#ShippingAddress_CountryRegion_Region`).val(),
            'ShippingAddress_PostalCode': $(`#ShippingAddress_PostalCode`).val(),
            'ShippingAddress_AddToAddressBook': $(`#ShippingAddress_AddToAddressBook`).prop('checked')
        };
    }

    fillAddressForm(values) {
        for (let prop in values) {
            $("#" + prop).val(values[prop]);
        }
    }

    showLoading() {
        this.loadingBox.show();
    }

    hideLoading() {
        this.loadingBox.hide();
    }

    initErrors() {
        const errors = $('.checkout__error').css('display');
        if (errors == "block") {
            const paymentErrors = document.getElementById("checkoutError");
            paymentErrors.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            let submitButton = $('.jsCheckoutSubmit');
            let newText = submitButton.data('payment-text');
            submitButton.text(newText);
        }
    }

    initDatePicker() {
        Inputmask({ alias: "datetime", inputFormat: "mm/dd/yyyy" }).mask(".request-dates__input");

        $('.jsShipmentDatePicker').each(function (i) {
            $(this).datepicker({
                //  startDate :$(this).data('startDate'),
                format: "mm/dd/yyyy",
                templates: {
                    leftArrow: '<i class="fal fa-angle-left"></i>',
                    rightArrow: '<i class="fal fa-angle-right"></i>'
                }
            });

            //disable year picker
            $(this).find('.datepicker-switch').each(function (j) {
                $(this).addClass('disable').on('click', function (e) {
                    e.stopPropagation();
                });
            });

        });
    }

    BuildGa4Object(dataset) {
        return {
            item_name: dataset.item_name || '',
            item_id: dataset.item_id || '',
            price: dataset.price || '',
            item_brand: dataset.item_brand || '',
            item_category: dataset.item_category || '',
            item_category2: dataset.item_category2 || '',
            item_category3: dataset.item_category3 || '',
            item_category4: dataset.item_category4 || '',
            item_category5: dataset.item_category5 || '',
            item_variant: dataset.item_variant || '',
            quantity: dataset.item_quantity || ''
        }
    }

    InitJsGa4() {
        const inst = this;
        const items = []
        document.querySelectorAll('.jsGa4-begin_checkout_item').forEach((element) => {
            if (element) {
                // GA4 begin checkout
                items.push(inst.BuildGa4Object(element.dataset))
            }
        });
        
        if (items.length) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'begin_checkout',
                ecommerce: {
                    items
                }
            });

            // Capture cart data from the page
            const cartItems = this.GetCartItems();
            if (cartItems.length) {
                inst.trackKlaviyoStartedCheckout(cartItems);
            }

        }
    }

    GetCartItems() {
        var cartDataDiv = document.getElementById('cartData');
        if (cartDataDiv) {
            try {
                var rawData = cartDataDiv.getAttribute('data-cart_lines');

                // Fix: Decode `&quot;` into actual quotes before parsing
                var jsonString = rawData.replace(/&quot;/g, '"');

                return JSON.parse(jsonString) || [];
            } catch (error) {
                console.error("Error parsing cart data:", error, cartDataDiv);
            }
        }
        return [];
    }

    trackKlaviyoStartedCheckout(cartLines) {        
        let itemNames = [];
        let categories = new Set();
        let items = cartLines.map(item => {            
            itemNames.push(item.ProductName);
            item.ProductCategories.forEach(category => categories.add(category));

            return {
                "ProductID": item.ProductID,
                "SKU": item.SKU,
                "ProductName": item.ProductName,
                "Quantity": item.Quantity,
                "ItemPrice": item.ItemPrice,
                "RowTotal": parseFloat(item.ItemPrice) * parseInt(item.Quantity),
                "ProductURL": item.ProductURL,
                "ImageURL": item.ImageURL,
                "ProductCategories": item.ProductCategories
            };
        });

        let klaviyoData = {
            "$event_id": new Date().getTime(), // Unique event ID
            "$value": cartLines.reduce((sum, product) => sum + (parseFloat(product.ItemPrice) * parseInt(product.Quantity)), 0),
            "ItemNames": itemNames,
            "CheckoutURL": window.location.href,
            "Categories": Array.from(categories),
            "Items": items
        };

        // Fire the Klaviyo event
        if (window.klaviyo) {
            console.log("Klaviyo event fired: Started Checkout", klaviyoData);
            klaviyo.track("Started Checkout", klaviyoData);
        }

        console.log("Klaviyo event fired: Added to Cart", klaviyoData);
    }

    sendGA4ShippingAndBillingInfo() {
        const inst = this;
        const items = []
        const shippingTier = document.querySelector('.jsGa4-shipping_info');
        document.querySelectorAll('.jsGa4-begin_checkout_item').forEach((element) => {
            if (element) {
                // GA4 Begin checkout
                items.push(inst.BuildGa4Object(element.dataset))
            }
        });

        if (items.length) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'add_shipping_info',
                shipping_tier: shippingTier.dataset.shipping_tier || '',
                ecommerce: {
                    items
                }
            });

            window.dataLayer.push({
                event: 'add_payment_info',
                payment_type: "Credit Card",
                ecommerce: {
                    items
                }
            });
        }
    }
}
