class AdminReport {
    constructor(params) {
        this.DESKTOP_BREAKPOINT = 992;
        this._container = $(params.containerSelector);
        this._filterContainer = $(params.filterContainerSelector);
        this._dataUrl = params.dataUrl;
        this._paging = typeof (params.paging) !== "undefined" ? params.paging : true;
        this._sorting = typeof params.sorting !== "undefined" ? params.sorting : true;
        this._caching = !!params.caching;
        this._pageSize = params.pageSize || 20;
        this._columns = params.columns;
        this._defaultSorting = params.defaultSorting;
        this._language = params.language;
        this._serverSide = typeof params.serverSide !== "undefined" ? params.serverSide : true;
        this._onRowClick = params.onRowClick;
    }

    init() {
        this._fromDate = this._filterContainer.find("input[name=fromDate]");
        this._toDate = this._filterContainer.find("input[name=toDate]");
        this._marketFilter = this._filterContainer.find("select[name=marketFilter]");

        this._groupBySelection = this._filterContainer.find("select[name=groupbyFilter]");

        const inst = this;

        this._dataTable = this._container.find(".dataTable").DataTable({      
            processing: true,
            serverSide: this._serverSide,
            searching: false,
            lengthChange: false,
            info: false,
            paging: this._paging,
            ordering: this._sorting,
            pagingType: "full_numbers",
            lengthMenu: [this._pageSize],
            "fnDrawCallback": function (oSettings) {
                if (!oSettings.json) return;
                const $paginationContainer = $(this).siblings('.dataTables__bottom');

                // hide pagination for single page
                $paginationContainer.toggle(oSettings.json.recordsTotal > inst._pageSize);

                $paginationContainer.find('.paginate_button').on('click', function () {
                    $(window).scrollTop(0);
                });
            },
            ajax: {
                method: "GET",
                url: this._dataUrl,
                data: function (data) {
                    data.fromDate = inst._fromDate.val();
                    data.toDate = inst._toDate.val();
                    data.market = inst._marketFilter.val();
                    data.groupBy = inst._groupBySelection.val();

                    const sorting = data.order && data.order.length
                        ? data.order[0]
                        : { column: inst._defaultSorting[0], dir: inst._defaultSorting[1] };

                    if (inst._serverSide) {
                        const sortColumn = data.columns[sorting.column];
                        data.sortOrder = (sortColumn.name || sortColumn.data) + "|" + sorting.dir;
                        data.length = data.length > 0 ? data.length : inst._pageSize;
                    }

                    delete data.search;
                    delete data.columns;
                    delete data.order;
                }
            },

            columns: this._columns,
            order: [this._defaultSorting],
            scrollXInner: true,
            language: {
                paginate: {
                    first: "<i class=\"fal fa-angle-double-left\"></i>",
                    previous: "<i class=\"fal fa-angle-left\"></i>",
                    next: "<i class=\"fal fa-angle-right\"></i>",
                    last: "<i class=\"fal fa-angle-double-right\"></i>"
                },
                processing: $('body > .loading-box').show(),
                loadingRecords: $('body > .loading-box').show(),
                emptyTable: this._language.noRecords
            },

            dom: "rt<\"hide\"i><\"dataTables__bottom\"p>"
        });

        this._filterContainer.find('.jsDownloadExcel').click(this._onDownloadExcelClicked.bind(this));

        this._filterContainer.on("keyup", ".jsGetReportButton", function (e) {
            if (e.keyCode === 13) {
                inst._dataTable.ajax.reload();
            }
        });

        this._filterContainer.on("click", ".jsGetReportButton", function (e) {
            inst._dataTable.ajax.reload();
        });       
        $.fn.DataTable.ext.pager.numbers_length = 5;       
    }

    _onDownloadExcelClicked(e) {
        e.preventDefault();
        const $link = $(e.currentTarget);
        const targetUrl = $link.attr("href") + "?" + $.param({ market: this._marketFilter.val(), fromDate: this._fromDate.val(), toDate: this._toDate.val(), groupBy: this._groupBySelection.val()});
        window.open(targetUrl);
    }

}
window.AdminReport = AdminReport;