class Product {

    constructor(divId) {
        if (divId) {
            this.DivContainerID = divId;
        } else {
            this.DivContainerID = document;
        }
    }

    /// Product handler

    addToCart(data, url, callback, isAddToCart) {
        var inst = this;
        $('body>.loading-box').show();
        data.requestFrom = "axios";
        axios.post(url, data)
            .then(function (result) {
                if (result.data.StatusCode == 0) {
                    notification.Warning(result.data.Message);
                }
                if (result.data.StatusCode == 1) {
                    notification.Success(result.data.Message, false);
                    if (callback) callback(result.data.CountItems);

                    if (isAddToCart) {
                        // GA4 add to cart
                        var item = inst.BuildGa4Object(data.jsGa4Data)
                        if (Object.keys(item).length) {
                            item.quantity = data.Quantity
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: 'add_to_cart',
                                ecommerce: {
                                    items: [item]
                                }
                            });
                        }
                    } else {
                        // GA4 add to wishlist
                        var item = inst.BuildGa4Object(data.jsGa4Data)
                        if (Object.keys(item).length) {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: 'add_to_wishlist',
                                ecommerce: {
                                    items: [item]
                                }
                            });
                        }
                    }

                    // Fetch cart items from <div data-cart_lines>
                    var cartItems = inst.GetCartItems();
                    var klaviyoData = inst.BuildKlaviyoObject(item, cartItems);

                    // Fire the Klaviyo event
                    if (window.klaviyo) {
                        console.log("Klaviyo event fired: Added to Cart", klaviyoData);
                        klaviyo.track("Added to Cart", klaviyoData);
                    }
                }
            })
            .catch(function (error) {
                notification.Error("Can not add the product to the cart.\n" + error.response.statusText);
            })
            .finally(function () {
                $('body>.loading-box').hide();
            });

        return false;
    }

    GetCartItems() {
        var cartDataDiv = document.getElementById('cartData');
        if (cartDataDiv) {
            try {
                var rawData = cartDataDiv.getAttribute('data-cart_lines');

                // Fix: Decode `&quot;` into actual quotes before parsing
                var jsonString = rawData.replace(/&quot;/g, '"');

                return JSON.parse(jsonString) || [];
            } catch (error) {
                console.error("Error parsing cart data:", error, cartDataDiv);
            }
        }
        return [];
    }

    BuildKlaviyoObject(item, cartItems) {
        return {
            "$value": cartItems.reduce((sum, product) => sum + (parseFloat(product.ItemPrice) * parseInt(product.Quantity)), 0),
            "AddedItemProductName": item.item_name,
            "AddedItemProductID": item.item_id,
            "AddedItemSKU": item.item_id,
            "AddedItemCategories": [
                item.item_category, item.item_category2, item.item_category3,
                item.item_category4, item.item_category5
            ].filter(Boolean), // Remove empty values
            "AddedItemImageURL": item.image_url,
            "AddedItemURL": item.product_url,
            "AddedItemPrice": parseFloat(item.price),
            "AddedItemQuantity": parseInt(item.quantity),
            "ItemNames": cartItems.map(p => p.ProductName),
            "CheckoutURL": window.location.origin + "/checkout",
            "Items": cartItems
        };
    }

    // use in Wishlist Page
    removeItem(data, url, message, callback, button) {
        var inst = this;
        $('body>.loading-box').show();
        axios.post(url, data)
            .then(function (result) {
                if (result.status == 200) {
                    notification.Success(message);
                    if ($("#my-wishlist").length > 0){
                        var wishlistItem = $(button).closest('.col-6');
                        wishlistItem && wishlistItem.remove();
                        var count = $('#countWishListInPage').val();
                        count = count - 1;
                        $('#countWishListInPage').val(count);
                        $('.favorites__controls-head').html(`${count} saved items`);
                    }                    
                    
                    var count = $('#countWishListInPage').val();
                    if (callback) callback(count);
                    
                    feather.replace();
                }
                if (result.status == 204) {
                    notification.Error(result.statusText);
                }
            })
            .catch(function (error) {
                notification.Error(error);
            })
            .finally(function () {
                $('body>.loading-box').hide();
            });
    }

    callbackAddToCart(selector, count) {
        /* If wishlist is added, a different selector would be needed*/
        cartHelper.SetCartReload(count);
    }


    AddToWishlistClick() {
        var inst = this;

        $(this.DivContainerID).find('.addToWishlist').each(function (i, e) {
            $(e).unbind().click(function () {
                var $button = $(this);
                var code = $button.data('code');
                var name = $button.data('name');
                var isWishlisted = $button.data('wishlisted') === "True";
                var data = { Code: code, Quantity: 0, RequestFrom: "axios" };

                var callback = (count) => {
                    //inst.callbackAddToCart('#js-wishlist', count);

                    if (!isWishlisted) {
                        $button.addClass("is-wishlisted");
                        $button.data("wishlisted", "True");
                    }
                    else {
                        $button.removeClass("is-wishlisted");
                        $button.data("wishlisted", "False");
                    }
                };

                if (!isWishlisted) {

                    var jsGa4Object = $(this).closest('.jsGa4-object')
                    var dataset = {}
                    if (jsGa4Object.length) {
                        dataset = jsGa4Object[0].dataset
                        var addToCartBtn = jsGa4Object.find('.addToCart')
                        
                        if(addToCartBtn){
                            dataset.quantity = addToCartBtn.attr('qty')
                        }
                    } else {
                        jsGa4Object = $(this).closest('.jsGa4-add-to-cart')
                        if (jsGa4Object.length) {
                            dataset = jsGa4Object[0].dataset

                            var addToCartBtn = jsGa4Object.find('.addToCart')

                            if(addToCartBtn){
                                dataset.quantity = addToCartBtn.attr('qty')
                            }
                        }
                    }

                    inst.addToCart({ code: code, jsGa4Data: dataset }, '/Wishlist/AddToCart', callback);
                }
                else {
                    if (name) {
                        inst.removeItem(data, '/Wishlist/ChangeCartItem', `${name} is removed from the wishlist`, callback, $button);
                    } else {
                        inst.removeItem(data, '/Wishlist/ChangeCartItem', "", callback, $button);
                    }
                }               
            });
        });
    }

    AddToCartClick() {
        var inst = this;

        $(this.DivContainerID).find('.addToCart').each(function (i, e) {
            $(e).unbind().click(function () {
                var code = $(this).attr('data');
                var recommendationId = $(this).attr('data-recommendation-id');
                var data = {
                    Code: code
                };

                if ($(this).attr('qty')) data.Quantity = $(this).attr('qty');
                if ($(this).attr('store')) data.Store = $(this).attr('store');
                if ($(this).attr('gygi')) data.GygiOption = $(this).attr('gygi');
                if ($(this).attr('gygiNote')) data.GygiCustomMessage = $(this).attr('gygiNote');
                if ($(this).attr('selectedStore')) data.SelectedStore = $(this).attr('selectedStore');

                var callback = (count) => {
                    inst.callbackAddToCart('.jsCartBtn', count);
                };

                var jsGa4Object = $(this).closest('.jsGa4-object')

                if (jsGa4Object.length) {
                    data.jsGa4Data = jsGa4Object[0].dataset
                } else {
                    jsGa4Object = $(this).closest('.jsGa4-add-to-cart')
                    if (jsGa4Object.length) {
                        data.jsGa4Data = jsGa4Object[0].dataset
                    }
                }
                
                inst.addToCart(data, '/DefaultCart/AddToCart', callback, true);

                if (recommendationId) {
                    axios.post("/Product/SendAddToCartClickTracking", { refcode: code, recommendationId: recommendationId });
                } 
            });
        });
    }


    AddAllToCartClick() {
        var inst = this;
        let productList = [];

        $(this.DivContainerID).find('.jsAddAllToCart').each(function (i, e) {
            $(e).unbind().click(function () {
                $('.loading-box').show();
                var url = $(this).attr('url');
                axios.post(url)
                    .then(function (result) {
                        if (result.data.StatusCode === 1) {
                            $(result.data.FavoritesWishListAdded).each(function (i, e) {
                                productList.push(inst.BuildGa4ObjectFromResponse(e));
                            });
    
                            if (Object.keys(productList).length) {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    event: 'add_to_cart',
                                    ecommerce: {
                                        items: productList
                                    }
                                });
                            }

                            notification.Success(result.data.Message);
                            cartHelper.SetCartReload(result.data.CountItems);
                        } else {
                            notification.Error(result.data.Message);
                        }
                    })
                    .catch(function (error) {
                        notification.Error(error);
                    })
                    .finally(function () {
                        $('.loading-box').hide();
                    });

            });
        });

    }

    // need to move recommeded products to product detail page instead
    RecommendedProducts() {
        let variantCode = $('.product-detail').attr('data-variantCode');
        const inst = this;
        axios.get("/Product/GetRecommendationId")
            .then(function (response) {
                let recommendationId = response.data;
                let url = '/Product/GetRecommendations';
                let query_params = new URLSearchParams(window.location.search);
                if (query_params.has('peeriusEIT')) {
                    url += '?peeriusEIT=';
                    url += query_params.get('peeriusEIT');
                }
                axios.get(url, { params: { variationCode: variantCode, recommendationId: recommendationId } })
                    .then(function (response) {
                        const source = $(response.data);
                        $("div.related-products").html(source.find("div#alternativeProductRecommendations").html()).show();
                        $("div.upsell-pro").html(source.find("div#crossSellProductRecommendations").html()).show();
                        const sideWidgetProducts = $('.upsell-pro').find('.product-thumbnail');
                        sideWidgetProducts.each(function (i, e) {
                            let productURL = $(e).find('.product-thumbnail__name').attr('href');
                            $(this).on('click', function () {
                                window.location = productURL;  
                            });
                        });
                        const product = new Product();
                        const productDetail = new ProductDetail();
                        product.AddToCartClick();
                        product.AddToWishlistClick();
                        productDetail.InitQuickView();
                    })
                    .catch(function (error) {
                        notification.Error(error);
                    })
                    .finally(function () {
                        //$('.loading-box').hide();
                    });
            });
    }

    BuildGa4Object(dataset) {
        let qty = dataset.quantity != "undefined" ? dataset.quantity : dataset.item_quantity;
        return {
            item_name: dataset.item_name || '',
            item_id: dataset.item_id || '',
            price: dataset.price || '',
            item_brand: dataset.item_brand || '',
            item_category: dataset.item_category || '',
            item_category2: dataset.item_category2 || '',
            item_category3: dataset.item_category3 || '',
            item_category4: dataset.item_category4 || '',
            item_category5: dataset.item_category5 || '',
            item_variant: dataset.item_variant || '',
            item_list_name: dataset.item_list_name || '',
            item_list_id: dataset.item_list_id || '',
            index: dataset.index || '',
            quantity: qty || '',
            product_url: dataset.item_product_url || '',
            image_url: dataset.item_image_url || ''
        }
    }

    BuildGa4ObjectFromResponse(object) {
        return {
            item_name: object.ItemName || '1',
            item_id: object.ItemId || '',
            price: object.Price || '',
            item_brand: object.ItemBrand || '',
            item_category: object.ItemCategory || '',
            item_category2: object.ItemCategory2 || '',
            item_category3: object.ItemCategory3 || '',
            item_category4: object.ItemCategory4 || '',
            item_category5: object.ItemCategory5 || '',
            item_variant: object.ItemVariant || '',
            item_list_name: object.ItemListName || '',
            item_list_id: object.ItemListId || '',
            index: object.Index || '',
            quantity: object.Quantity || '',
            product_url: dataset.item_product_url || '',
            image_url: dataset.item_image_url || ''
        }
    }
    
    Init() {
        // Init product \
        this.AddToWishlistClick();
        this.AddToCartClick();
        this.AddAllToCartClick();
        this.RecommendedProducts();
        //-- end
    }
}