class Reorder
{
    constructor(containerSelector)
    {
        this._container = $(containerSelector);
    }

    init()
    {
         this._container
             .on("change", ".jsSortSelector", this._onSortingChange.bind(this))
             .on("change", ".jsPageSizeSelector", this._onPageSizeChange.bind(this));
    }

    _onSortingChange(e)
    {
        const $control = $(e.currentTarget);
        window.location.href = this._replaceUrlParameter($control.data("url-template"), "sortBy", $control.val());
    }

    _onPageSizeChange(e)
    {
        const $control = $(e.currentTarget);
        window.location.href = this._replaceUrlParameter($control.data("url-template"), "pageSize", $control.val());
    }

    _replaceUrlParameter(template, parameterName, value)
    {
        return template.replace(encodeURIComponent("{" + parameterName + "}"), value)
    }
}
window.Reorder = Reorder;